// import {BrowserRouter as Routes, Route, Router, BrowserRouter } from "react-router-dom";
import { useEffect } from "react";
import About from "./pages/About";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Courses from "./pages/Courses";
import Register from "./pages/Register";
import {  BrowserRouter as Router,  Routes,  Route} from "react-router-dom";

function addscript(src) {
  const script = document.createElement("script");
  script.src = src;
  script.async = true;
  document.body.appendChild(script);
}
function App() {
  useEffect(() => {
    addscript("/assets/js/vendor/jquery.min.js");
    addscript("/assets/js/jquery.js");
    addscript("/assets/js/vendor/jquery.waypoints.min.js");
    addscript("/assets/js/main.min.js");
    addscript("/assets/js/scripts.min.js");
    addscript("/assets/js/custom.js");
    addscript("/assets/js/bootstrap-datepicker.js");
    addscript("/assets/js/bootstrap-datepicker.min.js");
    addscript("/assets/js/google-map.js");
    addscript("/assets/js/main.js");
    addscript("/assets/js/scripts.js");
    addscript("/assets/js/vendor/bootstrap.min.js");
    addscript("/assets/js/vendor/circle-progress.min.js");
    addscript("/assets/js/vendor/html5shiv.min.js");
    addscript("/assets/js/vendor/jquery.appear.js");
    addscript("/assets/js/vendor/jquery.appear.min.js");
    addscript("/assets/js/vendor/jquery.countTo.js");
    addscript("/assets/js/vendor/jquery.easing.1.3.js");
    addscript("/assets/js/vendor/jquery.flexslider-min.js");
    addscript("/assets/js/vendor/jquery.magnific-popup.min.js");
    addscript("/assets/js/vendor/jquery.stellar.min.js");
    addscript("/assets/js/vendor/owl.carousel.min.js");
    addscript("/assets/js/vendor/respond.min.js");
    addscript("/assets/js/other.js");


  });
  return (
    <Router>
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/courses" element={<Courses />} />
      <Route exact path="/register" element={<Register />} />
    </Routes>
   </Router>
  );
};

export default App;
