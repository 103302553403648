function Footer() {
    return (
        <>
        <div className="probootstrap-footer probootstrap-bg" style={{backgroundImage: `url(img/slider_3.jpg)`}}>
    <div className="container">
      <div className="row mb60">
        <div className="col-md-3">
          <div className="probootstrap-footer-widget">
            <h4 className="heading">About Giant Entrepreneurship College</h4>
            <p>Giant Entrepreneurship College is a Business School for the development of ideas, innovations, and a source of knowledge and skills to improve productivity.To harness appropriate educational resources to </p>
            <p><a href="about.php">Find out more...</a></p>
          </div> 
        </div>
        <div className="col-md-3">
          <div className="probootstrap-footer-widget probootstrap-link-wrap">
            <h4 className="heading">Quick Links</h4>
            <ul className="stack-link">
              <li><a href="#">About us</a></li>
              <li><a href="#">Register</a></li>
              <li><a href="#">Contact Us</a></li>
              <li><a href="#">Gallery</a></li>
              <li><a href="#">Testimonial</a></li>
            </ul>
          </div>
        </div>
        <div className="col-md-3">
          <div className="probootstrap-footer-widget">
            <h4 className="heading">Popular Tags</h4>
            <ul className="stack-link">
              <li><a href="#"></a></li>
              <li><a href="#">Professional Certificate Diploma(PCD).</a></li>
              <li><a href="#">Professional Advanced Diploma(PAD).</a></li>
              <li><a href="#">Professional Post Graduate Diploma (PPGD).</a></li>
              <li><a href="#">Skill Acquisition(JAMB classes )</a></li>
            </ul>
          </div> 
        </div>
        <div className="col-md-3">
          <div className="probootstrap-footer-widget probootstrap-link-wrap">
            <h4 className="heading">Subscribe to our news letter</h4>
            <p>To keep in touch with our latest news, kindly subcribe.</p>
            <form action="#" id="subscribe">
              <div className="form-field">
                <input type="text" name="email2" id="email2" className="form-control" placeholder="Enter your email" />
                <button className="btn btn-subscribe">Send</button>
              </div>
              <div id="response"></div>
            </form>
          </div>
        </div>
      </div>
      <div className="row copyright">
        <div className="col-md-6">
          <div className="probootstrap-footer-widget">
            <p><strong>GEC Business School is the trading name for Gaint Entrepreneurship college </strong></p>
          </div>
        </div>
      <div className="row copyright">
        <div className="col-md-6">
          <div className="probootstrap-footer-widget">
            <p>&copy; {new Date().getFullYear()} <a href="https://gecafrica.com.com/">Giant Entrepreneurship College</a>. Crafted by <a href="https://cretehost.com/">CreteHost Solutions</a></p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="probootstrap-footer-widget right">
            <ul className="probootstrap-footer-social">
              <li><a href="#"><i className="icon-twitter"></i></a></li>
              <li><a href="#"><i className="icon-facebook"></i></a></li>
              <li><a href="#"><i className="icon-instagram2"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
        </>
    );
}

export default Footer;