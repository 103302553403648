import Header from "../components/Header";
import Footer from "../components/Footer";
import gec1 from "../assets/img/gec_1.jpg";
import gec2 from "../assets/img/gec_2.jpg";
import gec3 from "../assets/img/gec_3.jpg";
import slider1 from "../assets/img/slider_1.jpg";
import slider2 from "../assets/img/slider_2.jpg";
import slider3 from "../assets/img/slider_3.jpg";
import slider10 from "../assets/img/slider_10.jpg";
import slider4 from "../assets/img/slider_4.jpg";
import slider5 from "../assets/img/slider_3.jpg";
import experience from "../assets/img/experience.jpeg";
import award from "../assets/img/award.jpg";
import curriculum from "../assets/img/flaticon/svg/curriculum-vitae.svg";
import presentation from "../assets/img/flaticon/svg/presentation.svg";
import certificate from "../assets/img/flaticon/svg/certificate.svg";

function Home() {
  return (
    <>
      <Header />
      <section className="probootstrap-slider flexslider">
        <div className="probootstrap-wrap-banner">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2">
                <div className="probootstrap-home-search probootstrap-animate">
                  <form method="post" action="api/subscribe.php">
                    <h2 className="heading">Search for the course to take</h2>
                    <div className="probootstrap-field-group">
                      <div className="probootstrap-fields">
                        <div className="search-field">
                          <i className="icon-envelope"></i>
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            id="email"
                            placeholder="Enter your email"
                          />
                        </div>
                        <div className="search-category">
                          <i className="icon-chevron-down"></i>
                          <select
                            className="form-control"
                            name="prog"
                            id="prog"
                          >
                            <option value="Choose Programme">
                              Choose Programme
                            </option>
                            <option value="Skill Acquisation">
                              Skill Acquisition
                            </option>
                            <option value="Professional Certificate  Diploma (PCD)">
                              Professional Certificate Diploma (PCD)
                            </option>
                            <option value="Professional AD Diploma (PAD)">
                              Professional AD Diploma (PAD)
                            </option>
                            <option value="Professional Post Graduate Diploma (PPGD).">
                              Professional Post Graduate Diploma (PPGD).
                            </option>
                          </select>
                        </div>
                      </div>
                      <button
                        className="btn btn-success"
                        id="submit"
                        name="submit"
                        type="submit"
                      >
                        <i className="icon-magnifying-glass t2"></i> Start
                        Search
                      </button>
                    </div>
                  </form>
                  <p className="mb0 text-left">
                    <small>
                      We will get back to you through our email with quote and
                      figures on how to get started{" "}
                      <a href="/">
                        email with quote and figures on how to get started
                      </a>
                    </small>{" "}
                  </p>
                </div>
                <div id="show"></div>
              </div>
            </div>
          </div>
        </div>
        <ul className="slides">
          <li
            style={{ backgroundImage: `url(${gec1})` }}
            className="overlay"
          ></li>
          <li
            style={{ backgroundImage: `url(${gec2})` }}
            className="overlay"
          ></li>
          <li
            style={{ backgroundImage: `url(${gec3})` }}
            className="overlay"
          ></li>
        </ul>
      </section>

      {/* <section className="probootstrap-section probootstrap-section-lighter">
  <div className="container">
    <div className="row heading">
      <h2 className="mt0 mb50 text-center">How it works</h2>
    </div>
    <div className="row">
      <div className="col-md-4">
        <div className="probootstrap-card text-center probootstrap-animate">
          <div className="probootstrap-card-media svg-sm colored">
            <a href="/register" ><img src={curriculum} className="svg" alt="Giant Entrepreneurship College" /></a>
            {/* <!--<img src="img/flaticon/svg/curriculum-vitae.svg" className="svg" alt="Giant Entrepreneurship College">--> 
          </div>
          <div className="probootstrap-card-text">
            <h2 className="probootstrap-card-heading">Apply for a Programme</h2>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="probootstrap-card text-center probootstrap-animate">
          <div className="probootstrap-card-media svg-sm colored">
            <a href="/courses" ><img src={presentation} className="svg" alt="Giant Entrepreneurship College" /></a>
            {/* <!--<img src="img/flaticon/svg/presentation.svg" className="svg" alt="Giant Entrepreneurship College">--> 
          </div>
          <div className="probootstrap-card-text">
            <h2 className="probootstrap-card-heading">Take Classes</h2>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="probootstrap-card text-center  probootstrap-animate">
          <div className="probootstrap-card-media svg-sm colored">
            <a href="/courses" ><img src={certificate} className="svg" alt="Giant Entrepreneurship College" /></a>
            {/* <!--<img src="img/flaticon/svg/certificate.svg" className="svg" alt="Giant Entrepreneurship College">--> 
          </div>
          <div className="probootstrap-card-text">
            <h2 className="probootstrap-card-heading">Get Certified</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> */}

      <section className="probootstrap-section">
        <div className="container">
          <div className="row heading">
            <h2 className="mt0 mb50 text-center">
              Bridge the Gap: Certify Your Experience
            </h2>
            <p style={{ textAlign: "center" }}>
              Are you a seasoned professional in healthcare, marketing, or any
              industry with years of experience but lacking a formal
              certificate? GEC Business School, in collaboration with Highstone
              Global University, offers experience-based certification programs
              designed specifically for you.
            </p>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-4 col-sm-12 col-xs-12">
              <h3 className="heading">Certify Your Expertise</h3>
              <p>
                Our programs recognize your industry experience and provide a
                structured pathway to award you a certificate in areas such as:
              </p>
              <ol>
                <li>Healthcare Management Certification</li>
                <li> Marketing and Digital Strategy Certification</li>
                <li>
                  Industry Leadership and Management Certification, among
                  others.
                </li>
              </ol>
              <h3 className="heading">Benefits</h3>
              <ol>
                <li>Formal recognition of your experience</li>
                <li>Enhanced career prospects</li>
                <li>Increased earning potential</li>
                <li> Networking opportunities</li>
                <li>Personalized learning pathways</li>
                <li> Opportunities to fill knowledge gaps and stay updated</li>
              </ol>
              <h3 className="heading">Program Features</h3>
              <ol>
                <li> Flexible online and offline learning options</li>
                <li> Self-paced, with a duration of 3-6 months</li>
                <li> Industry-relevant curriculum</li>
                <li>Expert faculty mentorship</li>
                <li>Practical, project-based assessments</li>
              </ol>
            </div>
            <div className="col-lg-6 col-md-4 col-sm-12 col-xs-12">
              <a href="/" className="probootstrap-hover-overlay">
                <img
                  src={experience}
                  alt="Giant Entrepreneurship College"
                  className="img-responsive"
                />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-4 col-sm-12 col-xs-12">
              <a href="/" className="probootstrap-hover-overlay">
                <img
                  src={slider2}
                  alt="Giant Entrepreneurship College"
                  className="img-responsive"
                />
              </a>
            </div>
            <div className="col-lg-6 col-md-4 col-sm-12 col-xs-12">
              <h3 className="heading">Eligibility</h3>
              <ol>
                <li>Minimum of 5 years of industry experience</li>
                <li>No prior certification required</li>
                <li> Passion for lifelong learning</li>
              </ol>
              <h3>Certification Pathways</h3>
              <ol>
                <li>Professional Certificate (Level 1)</li>
                <li>Advanced Professional Certificate (Level 2)</li>
                <li>Industry Expert Certificate (Level 3)</li>
              </ol>
              <h3 className="heading">Why Choose GEC Business School?</h3>
              <ol>
                <li> Industry-recognized certifications</li>
                <li> Expert faculty with real-world experience</li>
                <li> Collaborative learning environment</li>
                <li>Career support and guidance</li>
              </ol>
            </div>
          </div>
          <div className="row heading">
            <h2 className="mt0 mb50 text-center">Take the First Step</h2>
            <p style={{ textAlign: "center" }}>
              Invest in your future today <a href="/register">Register now</a>{" "}
              to validate your experience.
            </p>
          </div>
          
        </div>
      </section>

      <section className="probootstrap-section">
        <div className="container">
          <div className="row heading">
            <h2 className="mt0 mb50 text-center">
              GEC Entrepreneurship Award: Celebrating Visionary Entrepreneurs
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <img
                src={award}
                alt="Giant Entrepreneurship College"
                className="img-responsive"
              />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <p style={{ textAlign: "center" }}>
                The GEC Entrepreneurship Award is a prestigious recognition
                program designed to honor outstanding entrepreneurs who have
                made significant contributions to the growth and development of
                the nation. This esteemed award aims to acknowledge the
                innovative spirit, leadership, and dedication of entrepreneurs
                who have created a lasting impact on the economy and society.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-4 col-sm-12 col-xs-12">
              <h3 className="heading">Objective:</h3>
              <p>
                The primary objective of the GEC Entrepreneurship Award is to:
              </p>
              <ol>
                <li>
                  Recognize and reward entrepreneurs who have demonstrated
                  exceptional leadership, innovation, and entrepreneurial
                  spirit.
                </li>
                <li>
                  Encourage and inspire a new generation of entrepreneurs to
                  strive for excellence and make a positive impact on the
                  nation.
                </li>
                <li>
                  3. Foster a culture of entrepreneurship, innovation, and job
                  creation.
                </li>
              </ol>

              <h3 className="heading">Eligibility Criteria:</h3>
              <p>
                To be eligible for the GEC Entrepreneurship Award, nominees
                must:
              </p>
              <ol>
                <li>Be a citizen of the country.</li>
                <li>
                  Have founded or co-founded a business that has been in
                  operation for at least three years.
                </li>
                <li>
                  Have demonstrated significant growth, innovation, and job
                  creation.
                </li>
                <li>
                  Have made a positive impact on the community and the nation.
                </li>
              </ol>
            </div>
            <div className="col-lg-6 col-md-4 col-sm-12 col xs-12">
              <h3 className="heading">Selection Process:</h3>
              <p>
                A panel of esteemed judges, comprising successful entrepreneurs,
                business leaders, and industry experts, will select the winners.{" "}
              </p>
              <h3 className="heading">Benefits:</h3>
              <p>Winners of the GEC Entrepreneurship Award will receive:</p>
              <ol>
                <li>A prestigious trophy and certificate.</li>
                <li>Recognition and media coverage.</li>
                <li>
                  Access to networking opportunities with business leaders and
                  investors.
                </li>
                <li>A chance to inspire and mentor upcoming entrepreneurs.</li>
              </ol>
              <p>
                The GEC Entrepreneurship Award is a testament to the power of
                entrepreneurship in driving economic growth, innovation, and
                social change. By recognizing and celebrating outstanding
                entrepreneurs, we hope to inspire a new generation of leaders to
                strive for excellence and make a lasting impact on the nation.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="probootstrap-section">
  <div className="container">
    <div className="row heading">
      <h2 className="mt0 mb50 text-center">Our Programmes</h2>
    </div>
    <div className="row">
      <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 probootstrap-animate">
        <div className="service text-center">
          <div className="icon"><i className="icon-list2"></i></div>
          <h2 className="heading">Professional Advanced Diploma (PAD)</h2>
          <p>The Diploma Programme is uniquely designed on TOTAL ENTREPRENEUR CONCEPT packaged to include creativity, entrepreneurship and success values combined with skilled – based training towards raising future generation of leaders in innovation, Product Development and Nation building. The success values are possibility, mentality, capacity building, integrity, responsibility, diligence, character, goals, courage, persistence and sacrifice.
         </p>
        </div>
      </div>
      <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 probootstrap-animate">
        <div className="service text-center">
          <div className="icon"><i className="icon-list2"></i></div>
          <h2 className="heading">Professional Certificate Diploma (PGD)</h2>
          <p> A Professional Advanced Diploma holder from GEC Business School will stand out from the crowed of graduates. The courses are designed in partnership with employers and professional bodies, so you gain the skills they are looking for. We have a wide range of Professional Advanced Diploma Courses like, Entrepreneurship Management, Psychology, Business Administration, Marketing, Accountancy, Personnel Management, Sales Management, Public Administration and Purchasing and Supply Management.</p>
        </div>
      </div>
      <div className="clearfix visible-sm-block"></div>
      <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 probootstrap-animate">
        <div className="service text-center">
          <div className="icon"><i className="icon-list2"></i></div>
          <h2 className="heading">Professional Post Graduate Diploma (PPGD)</h2>
          <p>This PPGD will transform you into a versatile business adviser with advanced knowledge of areas including, entrepreneurship, advisory strategy, extensive application of psychology and personality development. The complexity and application of human behaviour in the management of resources for self enterprise. Also the striking and revealing fundamental influences that dictate and direct human behaviour in their entrepreneurial drive. </p>
        </div>
      </div>
      <div className="clearfix visible-lg-block visible-md-block"></div>
      <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 probootstrap-animate">
        <div className="service text-center">
          <div className="icon"><i className="icon-list2"></i></div>
          <h2 className="heading">Other Certifications</h2>
          <p>There are qualified and competent instructors and teachers for each subject. At the ends of each programme, students are advised to register for Institute for Chartered Entrepreneurship Consultants of Nigeria while proceeding to university.</p>
        </div>
      </div>
      <div className="clearfix visible-sm-block"></div>
      <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 probootstrap-animate">
        <div className="service text-center">
          <div className="icon"><i className="icon-list2"></i></div>
          <h2 className="heading">Jamb Classes</h2>
          <p>GEC Business School is also in affiliaton with Eagles Academy in offering tutorials to students who seek tertiary education like Jamb, Waec, e.t.c with additional Knowledge in required skill to aid such student wealth creator in university.</p>
        </div>
      </div>
      <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 probootstrap-animate">
        <div className="service text-center">
          <div className="icon"><i className="icon-list2"></i></div>
          <h2 className="heading">Skill Acquisition</h2>
          <p>This is where youths are equipped with technical/vocational skills and graduate who have acquired competencies in specific field and intend to further explore possibilities of self reliance </p>
        </div>
      </div>
      <div className="clearfix visible-lg-block visible-md-block"></div>
    </div>
  </div>
</section> */}

      <section
        className="probootstrap-section probootstrap-bg"
        style={{ backgroundImage: `url(${slider2})` }}
      >
        <div
          className="container text-center probootstrap-animate"
          data-animate-effect="fadeIn"
        >
          <h2 className="heading">MISSION STATEMENT</h2>
          <p className="sub-heading">
            To rebuild Nation’s economic value system by transforming
            individuals into creators of sustainable wealth.
          </p>
          <p>
            <a href="/register" className="btn btn-primary mb10">
              JOIN US
            </a>
          </p>
        </div>
      </section>

      <section className="probootstrap-section">
        <div className="container">
          <div className="row heading">
            <h2 className="mt0 mb50 text-center">
              Giant Entrepreneurship College{" "}
            </h2>
          </div>
          <div className="row probootstrap-gutter10">
            <div className="col-md-6 col-sm-6">
              <a href="/" className="probootstrap-hover-overlay">
                <img
                  src={slider2}
                  alt="Giant Entrepreneurship College"
                  className="img-responsive"
                />
                <div className="probootstrap-text-overlay">
                  <h3>Collaboration</h3>
                </div>
              </a>
            </div>
            <div className="col-md-6 col-sm-6">
              <a href="/" className="probootstrap-hover-overlay">
                <img
                  src={slider1}
                  alt="Giant Entrepreneurship College"
                  className="img-responsive"
                />
                <div className="probootstrap-text-overlay">
                  <h3>Techprenuership</h3>
                </div>
              </a>
            </div>
            <div className="clearfix visible-sm-block"></div>

            <div className="col-md-4 col-sm-6">
              <a href="/" className="probootstrap-hover-overlay">
                <img
                  src={slider3}
                  alt="Giant Entrepreneurship College"
                  className="img-responsive"
                />
                <div className="probootstrap-text-overlay">
                  <h3>Skill Acquisition</h3>
                </div>
              </a>
            </div>
            <div className="col-md-4 col-sm-6">
              <a href="/" className="probootstrap-hover-overlay">
                <img
                  src={slider4}
                  alt="Giant Entrepreneurship College"
                  className="img-responsive"
                />
                <div className="probootstrap-text-overlay">
                  <h3>Entreprenuership</h3>
                </div>
              </a>
            </div>
            <div className="clearfix visible-sm-block"></div>
            <div className="col-md-4 col-sm-6">
              <a href="/" className="probootstrap-hover-overlay">
                <img
                  src={slider10}
                  alt="Giant Entrepreneurship College"
                  className="img-responsive"
                />
                <div className="probootstrap-text-overlay">
                  <h3>Digital Marketting</h3>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- END: section --> */}

      <section className="probootstrap-half reverse">
        <div className="image-wrap">
          <div
            className="image"
            style={{ backgroundImage: `url(${slider5})` }}
          ></div>
        </div>
        <div className="text">
          <p className="mb10 subtitle">Why Choose Us</p>
          <h3 className="mt0 mb40">We have wealth of experience</h3>
          <p>
            The college is in relationship with the institute for Chartered
            Entrepreneurship Consultants of Nigeria. The Post Graduate School of
            Entrepreneurship Management (PSEM) of the college is affiliated with
            the institute and this joint working relationship will benefit our
            students
          </p>
          <p className="mb50">
            With the Institute for Chartered Entrepreneurship Consultants of
            Nigeria www.icenigeria.com , once a student has successfully
            completed the specialised Advanced Diploma as well as specialised
            Post Graduate Diploma in Psychology of Entrepreneurship Management.{" "}
          </p>
          <p>
            <a href="/contact" className="btn btn-primary mb10">
              Find out more
            </a>
          </p>
        </div>
      </section>

      <Footer />

      <div className="gototop js-top">
        <a href="/" className="js-gotop">
          <i className="icon-chevron-thin-up"></i>
        </a>
      </div>
    </>
  );
}

export default Home;
